<template>
  <div>
    <v-banner
      app
      sticky
      elevation="8"
      color="white"
      style="z-index: 6"
      class="mx-n8 mt-n4 px-4"
    >
      <v-row>
        <v-col class="d-flex align-center col-12 col-md">
          <h1 class="text-h6">Score Report Templates</h1>
        </v-col>
        <v-spacer class="d-none d-md-block"></v-spacer>
        <v-col v-if="selectedTemplate" class="col-12 col-md d-flex justify-end">
          <v-btn
            :disabled="loading"
            @click="variablesDialog = true"
            color="primary"
            class="mr-4"
          >
            Variables List
          </v-btn>
          <v-btn
            :loading="loading"
            :disabled="loading"
            @click="handleSave"
            color="primary"
          >
            Save
          </v-btn>
        </v-col>
      </v-row></v-banner
    >

    <v-row v-if="!selectedTemplate" class="mt-8 align-center">
      <v-col>
        <v-card
          max-width="700"
          outlined
          elevation="12"
          class="mx-auto"
          style="position: relative"
        >
          <v-card-title class="justify-center">
            Edit an Existing Report Template
          </v-card-title>
          <v-card-subtitle class="text-center"
            >Select an existing report template to edit.
          </v-card-subtitle>
          <v-card-text class="text-center">
            <div class="d-flex align-center justify-center">
              <v-progress-circular
                v-if="!reportTemplates"
                indeterminate
                size="48"
                color="primary"
              ></v-progress-circular>
            </div>

            <v-select
              v-if="reportTemplates"
              v-model="userSelectTemplate"
              hide-details
              :items="reportTemplates"
              item-text="name"
              item-value="id"
              label="Select Template"
              outlined
              return-object
            >
              <template v-slot:selection="{ item }">
                <div>
                  <div>{{ item.name }}</div>

                  <small class="text-grey">Created: {{ item.createdAt }}</small>
                </div>
              </template>
            </v-select>
            <v-btn
              :disabled="!userSelectTemplate"
              color="primary"
              class="my-6"
              @click="handleSelectTemplate"
            >
              Open Template
            </v-btn>
            <v-divider />
            <v-card-title class="justify-center">
              Create a New Report Template
            </v-card-title>
            <v-card-subtitle class="text-center"
              >Name and build a new report template.
            </v-card-subtitle>
            <v-text-field
              label="Template Name"
              persistent-hint
              hint="Required. Provide a short descriptive name."
              outlined
              v-model="newTemplateName"
            ></v-text-field>
            <v-btn @click="handleCreate" color="primary" class="mt-4">
              Create Template
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="showSnackBar"
      :timeout="2000"
      :color="snackBarColor"
      :elevation="24"
      transition="slide-x-reverse-transition"
      bottom
      right
      tile
    >
      <div class="text-center">{{ snackBarText }}</div>
    </v-snackbar>
  </div>
</template>

<script>
// import merge from "lodash/merge";
import { API, graphqlOperation } from "aws-amplify";
import {
  createReportTemplate,
  updateReportTemplate,
} from "@/graphql/mutations";
import { listReportTemplates } from "@/graphql/queries";
import linkBuilderMixin from "@/mixins/linkBuilderMixin";

export default {
  mixins: [linkBuilderMixin],

  data() {
    return {
      loading: false,
      reportMetaVars: null,
      metaVarTooltip: "Click to copy",
      currentGeneration: 0,
      generations: [
        "Generation X",
        "Baby Boomer",
        "Millennials",
        "Silent Generation",
      ],
      userSelectTemplate: null,
      selectedTemplate: null,
      newTemplateName: null,
      reportTemplates: null,
      variablesDialog: false,
      showGraphicEditorDialog: false,
      hasGraphicEditorData: false,
      showSnackBar: false,
      snackBarColor: "info",
      snackBarText: "",
      cacheTest: null,
      templateVersion: 2,
    };
  },
  computed: {
    currentGenerationGraphicData() {
      if (this.selectedTemplate && this.currentGeneration !== null) {
        return this.selectedTemplate.template[this.currentGeneration].content
          .howMuchLoseGraphic;
      } else {
        return null;
      }
    },

    // reportTemplateContentComponent() {
    //   this.reportTemplateIndex;
    //   return () =>
    //     import(
    //       `@/components/reportTemplates/${
    //         this.reportTemplates[this.reportTemplateIndex].componentName
    //       }.vue`
    //     );
    // },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      // get variables list
      const reportMeta = await this.getReportMeta();
      const newArray = [];

      for (const [key, value] of Object.entries(reportMeta.variables)) {
        const newObj = {};

        newObj.variable = key;
        newObj.example = value;

        newArray.push(newObj);
      }

      this.reportMetaVars = newArray;
      // console.log("this.reportmeta  ", reportMeta);
      this.getReportTemplates();
    },
    formatMetaVar(variable) {
      return `{{${variable}}}`;
    },
    async handleMetaCopy(variable) {
      const copied = await this.genericCopy(variable);

      if (copied) {
        this.metaVarTooltip = "Copied to clipboard!";
      }

      setTimeout(() => {
        this.metaVarTooltip = "Click to copy";
      }, 500);
    },
    async getReportMeta() {
      try {
        const response = await fetch(
          process.env.VUE_APP_SCORE_API + "score_report_meta_v3",
          {
            method: "POST",
            headers: {
              // Authorization: `Bearer ${process.env.NEEDTOKEN}`,
              "Content-Type": "application/json",
            },
            // body: JSON.stringify(data),
          }
        );

        if (response.ok) {
          const results = await response.json();
          if (results.success) {
            return results;
          }
        } else {
          throw new Error("Something failed fetching meta.");
        }
      } catch (error) {
        console.log("Score fetch error: ", error);
      }
    },

    async getReportTemplates() {
      // Get all report templates to set in select
      try {
        const response = await API.graphql(
          graphqlOperation(listReportTemplates)
        );
        const result = response.data.listReportTemplates;

        if (!result.items || result.items.length === 0) {
          throw new Error("No report templates could be found.");
        } else {
          this.reportTemplates = result.items.filter((t) => {
            return t.version === this.templateVersion;
          });
        }
      } catch (error) {
        this.showSnackBar = true;
        (this.snackBarText = "Error retrieving report templates: "), error;
        this.snackBarColor = "error";
      }
    },
    async handleSelectTemplate() {
      console.log(
        "/admin/report-template-builder/${this.userSelectTemplate.id}",
        `/admin/report-template-builder/${this.userSelectTemplate.id}`
      );
      this.$router.push({
        path: `/admin/report-template-builder/${this.userSelectTemplate.id}`,
      });
    },
    async handleCreate() {
      this.loading = true;

      // const template = new initialTemplate();
      // const newTemplate = JSON.stringify(template);

      try {
        const response = await API.graphql(
          graphqlOperation(createReportTemplate, {
            input: {
              template: JSON.stringify([
                {
                  generation: "",
                  content: [],
                },
              ]),
              current: false,
              version: this.templateVersion,
              name: this.newTemplateName,
            },
          })
        );
        const result = response.data.createReportTemplate;

        if (result) {
          this.$router.push({
            path: `/admin/report-template-builder/${result.id}`,
          });
        }
        console.log("handleCreate RESULT", result);
      } catch (error) {
        console.log("error", error);
      } finally {
        this.loading = false;
      }
    },
    handleShowGraphicEditorDialog(t) {
      this.cacheTest = Object.assign({}, t.content.howMuchLoseGraphic);
      this.showGraphicEditorDialog = true;
      this.hasGraphicEditorData = true;
    },
    handleCloseGraphicEditorDialog() {
      // reset forms if dialog is canceled with cached data that is set when dialog is opened
      console.log("handleCloseGraphicEditorDialog, cache", this.cacheTest);
      this.selectedTemplate.template[
        this.currentGeneration
      ].content.howMuchLoseGraphic = Object.assign({}, this.cacheTest);
      this.showGraphicEditorDialog = false;

      this.$nextTick(() => {
        this.hasGraphicEditorData = false;
      });
    },
    async handleSave() {
      this.loading = true;

      let updateData = Object.assign({}, this.selectedTemplate);
      updateData.template = JSON.stringify(updateData.template);

      try {
        const response = await API.graphql(
          graphqlOperation(updateReportTemplate, {
            input: updateData,
          })
        );
        const result = response.data.updateReportTemplate;
        result.template = JSON.parse(result.template);
        console.log("Saved Result", result);

        this.showSnackBar = true;
        this.snackBarText = "Template saved!";
        this.snackBarColor = "success";
      } catch (error) {
        console.log("Error updating report template: ", error);
        this.showSnackBar = true;
        this.snackBarText = "Error saving template";
        this.snackBarColor = "error";
      } finally {
        this.loading = false;
        this.showGraphicEditorDialog = false;

        this.$nextTick(() => {
          this.hasGraphicEditorData = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/report";
.theme--light.v-tabs-items {
  background-color: transparent;
}

.rs-report-edit {
  .v-tabs-items {
    padding: 0.5rem;
  }
  .rs-editor {
    &--light {
      &.ProseMirror {
        &-focused {
          box-shadow: 0 3px 40px rgba(magenta, 0.3);
        }
      }
    }
  }
  ::v-deep .ProseMirror {
    outline: 2px dashed cyan;
    background-color: rgba(cyan, 0.067);
    transition: all 0.15s linear;

    &-focused {
      background-color: rgba(magenta, 0.08);
      outline: 2px dashed magenta;
      box-shadow: 0 3px 40px rgba(magenta, 0.593);
    }
  }
}
</style>
